<script setup>
import {useStore} from "vuex";
import {computed, onMounted, ref} from "vue";
import {backendUrl, post} from "../../services/apiReq";
import {imageHandler} from "../../services/errors";

const store = useStore();
const productos = ref([]);

onMounted(async () => {
  await load();
});


const load = async () => {
  let res = await post("/carrito/get");
  productos.value = res.data?.productos;
}
const increment = async (producto) => {
  producto.cantidad++;
  await update();
}

const decrement = async (producto) => {
  if (producto.cantidad <= 1) return;
  producto.cantidad--;

  await update();
}

const update = async (producto, increment) => {


  post("/carrito/update", {
    user_id: store.getters.getUser?.id,
    productos: productos.value?.map((x) => {
      console.log(x)
      return {
        producto_codigo: x?.codigo,
        user_id: store.getters.getUser?.id,
        cantidad: x?.cantidad,
        observacion: x?.observacion
      }
    }),
    noMessage: true,
  });
}

const verificar = async () => {
  await update();
  location.href = "/verificacion";
}

const Delete = (producto) => {

  post("/carrito", {
    user_id: store.getters.getUser?.id,
    cantidad: 0,
    producto_codigo: producto?.codigo,
  });
  load();
}

const Subtotal = () => {
  let price = 0;
  productos.value?.forEach((x) => {
    let increment = x?.precio_final ?? x?.precio;
    price += increment * x?.cantidad;
  })
  return price;
}
</script>


<template>
  <div class='cart__container'>
    <table class='table-cart'>
      <thead>
      <tr>
        <th>Imagen</th>
        <th>Titulo</th>
        <th>Precio</th>
        <th>Cantidad</th>
        <th>Eliminar</th>
      </tr>
      </thead>

      <tbody>
      <tr v-for="(producto, key) in productos" :key="key">
        <td>
          <img class='prod-cart-img' :src="`${backendUrl}/files/2/${producto?.codigo?.trim()}.png`"
               @error="imageHandler($event)" alt="La Fortaleza Producto"/>
        </td>
        <td>{{ producto?.nombre }}</td>
        <td v-if="producto?.descuento">AR$ {{ producto?.precio_final }}</td>
        <td v-else>AR$ {{ producto?.precio }}</td>
        <td>
          <a class="count" @click="decrement(producto)">-</a>
          {{ producto?.cantidad }}
          <a class="count" @click="increment(producto)">+</a>
        </td>
        <td>
          <ion-icon @click="Delete(producto)" name="trash-outline" class="trash" title="Eliminar producto"></ion-icon>
        </td>
      </tr>

      </tbody>
    </table>

    <a class="atencion"> ATENCION : Productos que necesiten mantener cadena de frio, no podran ser enviados por correo.
      Muchas Gracias !!</a>

    <div class="subtotal">
      <h3 class='subtotal-h3'>Subtotal: </h3>
      <h3 class='subtotal-price'>AR$ {{ Subtotal() }}</h3>
    </div>

    <div class="btn__wrapper">
      <!--      <button class='buy-btn'>
              <a @click="update">Actualizar</a>
            </button>-->
      <button class='buy-btn'>
        <a @click="verificar">Finalizar Compra</a>
      </button>
      <button class='buy-btn'>
        <a href="/tienda">Seguir Comprando</a>
      </button>
    </div>
  </div>
</template>


<style scoped>
.atencion {
  display: block;
  margin-top: 1em;
}

.cart__container {
  margin-top: 200px !important;
  margin: 20px;
  padding: 20px;
}

.table-wrapper {
  overflow-x: auto;
}

.table-cart {
  width: 100%;
  border-collapse: collapse;
}

.table-cart th,
.table-cart td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  word-wrap: break-word;
}

.table-cart th {
  background-color: #f2f2f2;
  font-weight: 700;
  text-transform: uppercase;
  font-style: oblique;
}

.prod-cart-img {
  width: 90px;
  height: auto;
}

ion-icon {
  font-size: 26px;
}

.count {
  cursor: pointer;
  margin: 0 5px;
  padding: 2px 6px;
  color: #fff;
  background-color: dodgerblue;
  border-radius: 5px;
}

.trash {
  cursor: pointer;
}

.subtotal {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.subtotal-h3 {
  text-transform: uppercase;
  font-style: oblique;
  font-weight: 700;
}

.subtotal-price {
  padding: 10px;
  border-radius: 20px;
  box-shadow: 5px 5px rgba(0, 0, 0, 0.2);
  background-color: #f1f1f1;
  color: #08a208;
}

.btn__wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
}

.buy-btn {
  width: 200px;
  padding: 14px;
  margin: 20px 0;
  font-size: 16px;
  font-weight: 600;
  border: solid 1px #ccc;
  border-radius: 20px;
  transition: all 0.3s ease-in-out;
}

.buy-btn:hover {
  background-color: #f8853e;
  color: #fff;
}

@media only screen and (max-width: 768px) {
  .cart__container {
    margin-top: 200px !important;
    margin: 0px;
    padding: 5px;
  }

  .table-cart th,
  .table-cart td {
    padding: 6px;
    font-size: 14px;
  }

  .prod-cart-img {
    width: 60px;
  }

  .btn__wrapper {
    flex-direction: column;
    padding: 0 10px;
    margin-bottom: 80px;
  }

  .buy-btn {
    width: 100%;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 430px) {

  .cart__container {
    padding: 2px;
  }

  .table-cart th,
  .table-cart td {
    font-size: .7rem;
  }

  .prod-cart-img {
    width: 50px;
  }

  .count {
    margin: 0 2px;
    padding: 2px 6px;
  }

  ion-icon {
    font-size: 22px;
  }

  .buy-btn {
    font-size: 14px;
  }
}

@media only screen and (max-width: 319px) {
  .table-cart {
    table-layout: fixed;
  }
}

</style>
