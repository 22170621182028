<script setup>
import { computed, ref, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";

const store = useStore();
const loggedIn = ref(false);
const isMenuOpen = ref(false);
const windowWidth = ref(window.innerWidth);

const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value;
};

const closeMenu = () => {
  isMenuOpen.value = false;
};

const logout = () => {
  store.dispatch("logout");
};

const User = computed(() => store.getters.getUser);

const handleResize = () => {
  windowWidth.value = window.innerWidth;
};

onMounted(() => {
  window.addEventListener("resize", handleResize);
});

onUnmounted(() => {
  window.removeEventListener("resize", handleResize);
});
</script>

<template>
  <header class="header">
    <a href="/">
      <div class="logo">
        <img class="img-logo" src="../../public/images/laFortaleza-logo.png" alt="La Fortaleza logo" title="Logo La Fortaleza" />
      </div>
    </a>

    <div class="menu-overlay" :class="{ 'open': isMenuOpen }" @click="closeMenu"></div>
    
    <div class="buy-online" v-if="windowWidth > 1090">  
      <a href="/tienda" class="buy-online__link active">Compra Online</a>
    </div>
    <div v-else>
      <a href="/tienda">
        <ion-icon name="storefront-outline" title="Compra Online"></ion-icon>
      </a>
    </div>
    <ul class="navbar" :class="{ 'open': isMenuOpen }">
      <li><a href="/">Inicio</a></li>
      <li><a href="/nosotros">Nosotros</a></li>
      <li><a href="/contacto">Contacto</a></li>

      <li v-if="windowWidth > 1090">
        <a href="/compras"><ion-icon name="bag-check-outline" title="Mis Compras"></ion-icon></a>
      </li>
      <li v-else>
        <a href="/compras" @click="toggleMenu">Mis Compras</a>
      </li>
    </ul>

    
    <div class='cart-container'>
      <a href="/carrito">
        <ion-icon name="cart-outline" title="Mi Carrito"></ion-icon>
      </a>
    </div>

    <div class="user-dropdown" @click="toggleDropdown">
      <a class="user" href="/login" v-if="!User">
        <ion-icon name="person-circle-outline"></ion-icon>
        Iniciar Sesión
      </a>
      <div v-else>
        <a class="user" @click="logout">Cerrar sesión</a>
        <p>{{ User.document }}</p>
      </div>
    </div>

    <div class="menu-icon" @click="toggleMenu">
      <ion-icon name="menu-outline"></ion-icon>
    </div>
  </header>
</template>


<style scoped>
.header {
  z-index: 1000;
  position: fixed;
  width: 100%;
  height: 160px;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: #fff;
  box-shadow: 4px 1px #f2f2f2;
  padding: 28px 12%;
  transition: all .5s ease;
}

.header-icons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.store-container,
.cart-container {
  display: flex;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 4%;
  width: 20%;
}

.logo .img-logo {
  width: 150px;
  height: 150px;
}

.navbar {
  display: flex;
}

.buy-online {
  margin-bottom: 10px;
  text-transform: uppercase;
}

.buy-online__link {
  color: #D63600 !important;
  font-weight: 600;
  font-family: "Poppins", "sans-serif" !important;
  font-size: 1.1rem;
}

.navbar a {
  color: #000;
  font-size: 1.1rem;
  font-weight: 500;
  padding: 5px 0;
  margin: 0px 30px;
  transition: all .5s ease;
}

.navbar a:hover {
  color: #D63600;
}

.navbar a.active {
  color: #D63600 !important;
  font-weight: 600;
}

.main {
  display: flex;
  align-items: center;
}

.main a {
  margin-right: 25px;
  margin-left: 10px;
  color: #000;
  font-size: 1.1rem;
  font-weight: 500;
  transition: all .5s ease;
}

.user {
  display: flex;
  align-items: center;
}

ion-icon {
  font-size: 32px;
  margin-right: 7px
}

.main a:hover {
  color: #f45e02;
}

.menu-icon {
  color: #000;
  font-size: 35px;
  cursor: pointer;
  z-index: 10001;
  display: none !important;
}

.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  display: none;
}

.navbar.open {
  display: block;
}

.menu-overlay.open {
  display: block;
}

@media only screen and (max-width: 1280px) {
  .header {
    padding: 14px 2%;
    transition: .2s;
  }

  .navbar a {
    padding: 5px 0;
    margin: 0px 20px
  }
}

@media only screen and (max-width: 1090px) {
  .menu-icon {
    display: block !important;
  }

  .navbar {
    position: absolute;
    top: 100%;
    right: -100%;
    width: 270px;
    height: 100vh;
    background: #f5f5f5;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-top-left-radius: 10px;
    transition: all 0.5s ease;
    z-index: 10001;
  }

  .navbar a {
    cursor: pointer;
    display: block;
    margin: 12px 0;
    padding: 0px 25px;
    transition: all 0.5s ease;
  }

  .navbar li a:hover {
    color: #f45e02;
    transform: translateY(5px);
  }

  .navbar a.active {
    color: #000;
  }

  .navbar.open {
    right: 0;
  }

  .menu-overlay {
    display: none;
  }

  .menu-overlay.open {
    display: block;
  }
}

@media only screen and (max-width: 528px) {
  .logo .img-logo {
    width: 130px;
    height: 130px;
  }
  .user {
    font-size: .9rem;
  }
  .dni {
    font-size: .9rem;
  }
  ion-icon {
    font-size: 28px !important;
    margin-right: 6px
  }
}
</style>
