<script setup>
import {reactive} from "vue";
import {useStore} from "vuex";

const form = reactive({});
const store = useStore();
const login = async () => {
  await store.dispatch("login", form);
}
</script>


<template>
  <body class="login-body">
    <section class="login__wrapper" title="Local La Fortaleza">
      <section class="login-section">
        <div class="form-box">
          <div class="form-value">
            <form @submit.prevent="login">
              <h2 class="h2-login">Iniciar sesión</h2>

              <div class="inputbox">
                <span>
                  <i class="fa fa-envelope"></i>
                </span>
                <input class="input-login" type="email" placeholder="" value="" required v-model="form.email" />
                <label class="label-login" for="email">Email</label>
              </div>

              <div class="inputbox">
                <span>
                  <i class="fa fa-lock"></i>
                </span>
                <input class="input-login" type="password" placeholder="" value="" required v-model="form.password" />
                <label class="label-login" for="password">Contraseña</label>
              </div>

              <button class="login-btn">Iniciar sesión</button>

              <div class="register__link">
                <p>
                  ¿No tienes una cuenta?
                  <a href="/signup">Crea una cuenta</a>
                </p>
              </div>
            </form>
          </div>
        </div>
      </section>
    </section>
  </body>
</template>


<style scoped>
.login__wrapper {
    margin: 0;
    padding: 0;
    background: url(../../public/images/local-fortaleza2.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-section {
    display: flex;
    justify-content: center;
    min-height: 100vh;
    min-width: 100%;
    align-items: center;
}

.form-box {
    position: relative;
    width: 400px;
    height: 500px;
    background: transparent;
    border: 2px solid rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    backdrop-filter: blur(15px);
    display: flex;
    justify-content: center;
    align-items: center;

}

.h2-login {
    font-size: 2em;
    color: #fff;
    text-align: center;
    width: 100%;
}

.inputbox {
    position: relative;
    margin: 30px 0;
    width: 310px;
    border-bottom: 2px solid #fff;
}

.inputbox .label-login {
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    color: #fff;
    font-size: 1em;
    pointer-events: none;
    transition: .5s;
}

.input-login:focus ~ .label-login, 
.input-login:valid ~ .label-login{
    top: -5px;
}

.inputbox .input-login {
    width: 100%;
    height: 50px;
    background: transparent;
    border: none;
    outline: none;
    font-size: 1em;
    padding: 35px 0 5px;
    color: #fff;
}

.inputbox i {
    position: absolute;
    right: 8px;
    color: #fff;
    font-size: 1.2em;
    top: 20px;
}

.forget {
    margin: -5px 0 15px;
    font-size: .9em;
    color: #fff;
    display: flex;
    justify-content: center;
}

.forget .label-login .input-login{
    margin-right: 3px;
}

.forget .input-login {
    color: #fff;
}

.login-btn {
    margin: 10px 0;
    width: 100%;
    height: 40px;
    border-radius: 40px;
    background: #fff;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 1em;
    font-weight: 600;
}

.login-btn:hover {
    background: #F8853E;
    color: #fff;
    transition: 500ms ease;
    transform: scale(1.1);
}

.register__link {
    font-size: .9em;
    color: #000;
    text-align: center;
    margin: 25px 0 10px;
}

.register__link p {
    color: #fff;
}

.register__link p a {
    color: #fff;
    text-decoration: none;
    font-weight: 600;
}

.register__link p a:hover {
    text-decoration: underline;
}

@media screen and (max-width: 420px) {
    .login__wrapper {
      padding: 40px 0;
    }

    .form-box {
      width: 95%;
      max-width: 100%;
    }

    .h2-login {
      font-size: 1.6em;
    }

    .inputbox {
      width: 100%;
    }

    .login-btn {
      font-size: 0.9em;
    }
  }
</style>