<script>
export default {
}
</script>

<template>
    <div class="faq-container">
      <h2>Preguntas frecuentes</h2>
      <div class="tab">
        <input type="radio" name="acc" id="acc1"/>
        <label for="acc1">
          <h3>01</h3>
          <h4>¿Cómo me creo una cuenta?</h4>
        </label>
          <div class="content">
            <p>Para crear una cuenta en "La Fortaleza", debes presionar "Iniciar Sesión" y luego, en la parte inferior del formulario, encontrarás un enlace que debes presionar llamado "Crea una cuenta". Este te redireccionará al formulario de registro, donde debes completar todos los campos con tu información para crear una cuenta y poder comenzar una orden de compra.</p>
          </div>
      </div>

      <div class="tab">
        <input type="radio" name="acc" id="acc2"/>
        <label for="acc2">
          <h3>02</h3>
          <h4>¿Cómo se genera una orden?</h4>
        </label>
          <div class="content">
            <p>Una vez iniciada la sesión, podremos agregar productos al carrito, observando el detalle de cada uno de ellos. Para administrar la compra, nos dirigiremos a la parte superior donde se encuentra el carrito de compras. Al hacer click en él, aparecerán todos los productos añadidos. Para continuar con la compra, en la parte inferior, hacemos click en el botón "Verificar", donde se nos pedirá completar todos los datos personales. Una vez que los datos estén correctos, presionaremos en "Siguiente" y completaremos la información de pago y envío. Finalmente, podremos ver e imprimir nuestro ticket. NO REALIZAR TRANSFERENCIA SI SU COMPRA NO SE CONFIRMO.</p>
          </div>
      </div>

      <div class="tab">
        <input type="radio" name="acc" id="acc3"/>
        <label for="acc3">
          <h3>03</h3>
          <h4>¿Cómo veo el estado de mis compras?</h4>
        </label>
          <div class="content">
            <p>Una vez realizada una o más órdenes de compra, podrás dirigirte a la bolsa de compra con una tilde, donde encontrarás el estado de tu compra y su respectivo ticket. El estado de la compra se confirmará cuando se haya verificado el stock de la orden. Una vez confirmado, el vendedor se contactará con usted para gestionar la transferencia. Si ya le ha realizado una transferencia previamente, de todas formas debe esperar la confirmación de la orden.</p>
          </div>
      </div>

      <div class="tab">
        <input type="radio" name="acc" id="acc4"/>
        <label for="acc4">
          <h3>04</h3>
          <h4>¿Cómo recupero mi contraseña?</h4>
        </label>
          <div class="content">
            <p>Si pierde su contraseña, debe comunicarse con los dueños del local vía WhatsApp. Después de esto, debe enviar todos sus datos para corroborar su identidad y enviarle su nueva contraseña. Una vez que haya completado este proceso, el equipo le asignará su nueva contraseña, con la cual podrá ingresar. Y recuerde no perder su contraseña nuevamente.</p>
          </div>
      </div>
    </div>
</template>


<style scoped>
    .faq-container {
        margin-top: 4em !important;
        margin: 0 40px;
        max-width: 600px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    .faq-container h2 {
        color: #333;
        font-size: 28px;
        text-transform: uppercase;
    }

    .faq-container .tab {
        position: relative;
        background: #fff;
        padding: 0 20px 20px;
        box-shadow: 0 15px 25px rgba(0, 0, 0, 0.05);
        overflow: hidden;
    }

    .faq-container .tab input {
        appearance: none;
    }

    .faq-container .tab label {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .faq-container .tab label::after {
        content: '+';
        position: absolute;
        right: 20px;
        font-size: 2em;
        color: rgba(0, 0, 0, 0.1);
        transition: transform 1s;
    }

    .faq-container .tab:hover label::after {
        color: #333;
    }

    .faq-container .tab input:checked ~ label::after {
        transform: rotate(135deg);
        color: #fff;
    }

    .faq-container .tab label h3 {
        width: 40px;
        height: 40px;
        background: #333;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 1.25em;
        border-radius: 5px;
        margin-right: 10px;
    }

    .faq-container .tab input:checked ~ label h3 {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        color: rgba(255, 255, 255, 0.2);
        font-size: 8em;
        justify-content: flex-end;
        padding: 20px;
    }

    .faq-container .tab:nth-child(2) label h3 {
        background: linear-gradient(135deg, #fd6e6a, #ffc600)
    }
    .faq-container .tab:nth-child(3) label h3 {
        background: linear-gradient(135deg, #fd6e6a, #ffc600)
    }
    .faq-container .tab:nth-child(4) label h3 {
        background: linear-gradient(135deg, #fd6e6a, #ffc600)
    }
    .faq-container .tab:nth-child(5) label h3 {
        background: linear-gradient(135deg, #fd6e6a, #ffc600)
    }

    .faq-container .tab label h4 {
        position: relative;
        font-weight: 500;
        color: #333;
        z-index: 10;
    }

    .faq-container .tab input:checked ~ label h4 {
        background: #fff;
        padding: 2px 10px;
        color: #333;
        border-radius: 2px;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
    }

    .faq-container .tab .content {
        max-height: 0;
        transition: 1s;
        overflow: hidden;
    }

    .faq-container .tab input:checked ~ .content {
        max-height: 100vh;
    }

    .faq-container .tab .content p {
        position: relative;
        padding: 10px 0;
        color: #333;
        z-index: 10;
    }

    .faq-container .tab input:checked ~ .content p {
        color: #fff;
    }

    @media only screen and (max-width: 448px) {
      .faq-container {
        margin: 0 20px;
    }
      .faq-container h2 {
        font-size: 24px;
    }
    }
</style>