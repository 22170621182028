<script setup>
  const year = new Date().getFullYear();
</script>

<template>
    <footer class='footer'>
      <div class="footer-container">
        <div class="footer-row">

          <div class="footer-links">
            <h4>Links</h4>
            <ul>
              <li><a href="/">Inicio</a></li>
              <li><a href="/tienda">Tienda</a></li>
              <li><a href="/nosotros">Nosotros</a></li>
              <li><a href="/contacto">Contacto</a></li>
              <li><a href="/carrito">Carrito</a></li>
            </ul>
          </div>

          <div class="footer-links">
            <h4>Contáctanos</h4>
            <div class='social-link'>
              <a href="https://www.instagram.com/dist.lfortaleza/" target='_blank' title="Instagram La Fortaleza">
                <ion-icon class='img-logo' name="logo-instagram"></ion-icon>
              </a>
              <a href="https://www.facebook.com/Dist.LFortaleza/" target='_blank' title=" Facebook La Fortaleza">
                <ion-icon class='img-logo' name="logo-facebook"></ion-icon>
              </a>
              <a href="https://www.whatsapp.com/catalog/5491128347514/?app_absent=0" target='_blank' title="Whatsapp La Fortaleza">
              <ion-icon class='img-logo' name="logo-whatsapp"></ion-icon>
              </a>
            </div>
          </div>

          <div class="footer-links">
            <h4>Dirección</h4>
            <div>
              <iframe class="maps" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d818.85919396067!2d-58.277266525138955!3d-34.82010927046223!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95a3298071ff877b%3A0x8129629e64d2c845!2sDISTRIBUIDORA%20LA%20FORTALEZA!5e0!3m2!1ses!2sar!4v1713316228726!5m2!1ses!2sar" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title="Dirección La Fortaleza"></iframe>
            </div>
          </div>

          <div class="footer-links">
            <h4>Creadores</h4>
            <div class="devs-container">
              <p>&copy; {{year}} developed by Máximo Felipe Resi & Martín Catalano Resi. All rights reserved.</p>
            </div>
          </div>
          
        </div>
      </div>
    </footer>
</template>


<style scoped>
.footer-container {
    max-width: 1200px;
    margin: 0 auto;
}

.footer {
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    padding: 80px 0;
}

.footer-row {
    display: flex;
    flex-wrap: wrap;
}
   
.footer-links {
    width: 25%;
    padding: 0 15px; 
}

.footer-links h4 {
    font-size: 20px;
    color: #000;
    margin-bottom: 25px;
    font-weight: 500;
    border-bottom: 2px solid #ff731c;
    padding-bottom: 10px;
    display: inline-block;
}

.footer-links ul li a {
    font-size: 18px;
    font-weight: 600;
    text-decoration: none;
    color: #2b2b2b;
    display: block;
    margin-bottom: 15px;
    transition: all .3s ease;
}

.footer-links ul li a:hover {
    color: #ff731c;
    padding-left: 6px;
}

.social-link a {
    display: inline-block;
    min-height: 40px;
    width: 40px;
    background-color: #515151;
    margin: 0 10px 10px 0;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
    color: #fff;
    transition: all .5s ease;
} 

.social-link a:hover {
    background-color: #ff731c;
}

ion-icon {
    position: relative;
    top: 5px !important;
    font-size: 24px;
}

.maps {
    opacity: .4;
    border: 2px double black;
    width: 300px; 
    height:250px; 
}

.maps:hover {
    opacity: 1;
    border: 2px double #ff6200;
}

.devs-container {
  padding-left: 10px;
}

.footer__copyright {
    padding: 0px 10px;
    color: #808080;
    font-weight: 600;
    text-align: center;
}

@media only screen and (max-width: 991px) {
    .footer-row {
        text-align: center;
    }

    .footer-links {
        width: 100%;
        margin-bottom: 30px;
    }

    .maps {
        opacity: .4;
        border: 2px double black;
        width: 330px; 
        height:280px; 
    }
}

@media only screen and (max-width: 400px) {
  .maps {
    opacity: .4;
    border: 2px double black;
    width: 280px; 
    height:240px; 
  }
}
</style>
