import {post} from "../../services/apiReq";

export default {
    state: () => ({}),
    getters: {

    },
    actions: {
        async getColumns (value) {
            let response = await post("/table/columns", value);
            return response;
        },
        async getTables (value) {
            let response = await post("/table/tables", value);
            return response;
        },
        async insert ({}, value) {
            return await post("/table/insert", value);
        },
        async insertMultiple (value) {
            return await post("/table/insertMultiple", value);
        },
        async update (value) {
            return await post("/table/update", value);
        },
        async get ({}, value) {
            return await post("/table/values", value);
        },
        async count (value) {
            return await post("/table/count", value);
        },
        async remove (value) {
            return await post("/table/delete", value);
        },
        async removes (value) {
            return await post("/table/deletes", value);
        },
        async createIfNotExists (value) {
            return await post("/table/create", value);
        },
        async import (value) {
            return await post("/table/import", value);
        },
        verify (value) {
            let keys = Object.keys(value);
            let values = {};

            for (const column of keys) {
                let col = value[column];
                if (!col?.nullable && !col?.value && col?.value !== 0) {
                    show(`Debe completar el campo ${translations[column] ?? column}`)
                    return;
                }
                values[column] = col?.value;
            }
            return values;
        }
    },
};