<script setup>
import {onMounted, ref} from "vue";
import {useStore} from "vuex";

const ordenes = ref([]);
const store = useStore();
onMounted(async () => {
  let res = await store.dispatch("get", {
    tableName: "ordenes",
    perPage: 999,
    search: {
      strict: true,
      param: "user_id",
      value: store.getters.getUser.id
    }
  });

  ordenes.value = res?.data?.data;
})
</script>


<template>
    <body class="body-shopping">
        <div class="list-container">
            <h2 class="title">Lista de Mis Compras</h2>
            <ul class="compras-list">
                <li class="compra-item">
                    <div class="compra-info">
                        <div class="column-title">Estado</div>
                        <div class="column-title">Ticket</div>
                    </div>
                </li>
                <li class="compra-item" v-for="(item, key) in ordenes" :key>
                    <div class="compra-info" v-if="(item?.status)?.toLowerCase() === 'confirmado'">
                        <span class="fecha">{{ item?.status?.toLowerCase() }}</span>
                        <a :href="`https://lafortaleza.com.ar/verificacion/envio/ticket?id=${item?.id}`" target="_blank" class="enlace-ticket">Ver ticket de compra</a>
                    </div>
                  <div class="compra-info" v-else>
                    <p>
                      Ud. podrá visualizar la orden de compra una vez el estado de la misma sea confirmado.
                      La fortaleza se pondra en contacto con usted para confirma el envio.
                      ¡Muchas gracias!
                    </p>
                  </div>
                </li>
            </ul>
        </div>
    </body>
</template>

<style scoped>
.body-shopping {
    display: flex;
    justify-content: center;
    align-items: center;
}

.list-container {
    margin-top: 120px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    max-width: 600px; 
}

.title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
    text-align: center;
}

.compras-list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    padding: 0;
}

.compra-item {
    background-color: #f5f5f5;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 10px;
    width: 100%;
}

.compra-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%; 
    width: 100%;
}

.fecha,
.enlace-ticket,
.column-title {
    font-weight: bold;
    text-align: center;
}

.fecha {
    margin-bottom: 5px;
    font-size: 16px;
}

.enlace-ticket {
    text-decoration: none;
    color: #007bff;
    transition: color 0.3s ease;
    margin-left: 250px; /* Agregado margen izquierdo para separar */
}

.enlace-ticket:hover {
    color: #0056b3;
}

.column-title {
    font-size: 16px;
}
.hidden {
  display: none;
}
@media only screen and (max-width: 600px) {
    .list-container {
        margin-top: 140px;
        margin-bottom: 60px;
    }

    .compra-item {
        padding: 8px;
    }

    .enlace-ticket {
        margin-left: 70px; /* Agregado margen izquierdo para separar */
    }

    .fecha,
    .enlace-ticket {
        font-size: 14px;
    }
}


</style>
