<script setup>
import {onMounted, ref, watch} from "vue";
import {useStore} from "vuex";
import {post} from "../../services/apiReq";

const formaDeEnvio = ref("retiro_local");
const metodoDePago = ref("efectivo");
const store = useStore();

const productos = ref([]);
const isFrio = ref(false);
const productosFrios = ref([]);
const showModalProductos = ref(false);

onMounted(async () => {
  let res = await post("/carrito/get");
  productos.value = res.data?.productos;

  let productosFrio = productos.value?.filter((x) => x?.frio?.length > 0);
  isFrio.value = productosFrio?.length > 0;
  productosFrios.value = productosFrio;
})

const save = () => {
  let data = localStorage.getItem("verificationData");
  data = JSON.parse(data);
  data.envio = formaDeEnvio.value;
  data.pago = metodoDePago.value;
  localStorage.removeItem("verificationData");
  localStorage.setItem("verificationData", JSON.stringify(data));
}
const Continue = async () => {
  // || Crear orden
  save();
  let data = localStorage.getItem("verificationData");
  data = JSON.parse(data);
  let res = await post("/order", {
    info: {...data, user_id: store.getters.getUser.id, status: "Pendiente"},
  });
  location.href = "/verificacion/envio/ticket?id=" + res?.data?.order_id;
}

watch(formaDeEnvio, (new_val) => {
  if (formaDeEnvio.value === 'envio_domicilio' || formaDeEnvio.value === 'envio_correo') metodoDePago.value = "transferencia";
  save();
})
watch(metodoDePago, (new_val) => {
  save();
})
</script>


<template>
  <body>
  <div class="custom-modal-html modalProductosFrios" v-if="showModalProductos" radius-border>
    <div class="title" flex-center>
      <h2>Productos con cadena de frio</h2>
    </div>
    <div class="content">
      <ul class="productosfrio">
        <li class="productoFrio" v-for="(producto, key) in productosFrios">
          <a :href="`/itemdetail?producto=${producto?.codigo}`">{{ producto?.nombre }}</a>
        </li>
      </ul>
    </div>
    <button class="custom-button-html cerrar" @click="() => showModalProductos = false">Cerrar</button>
  </div>
  <div class="shipping-container">
    <div class="shipping-form">
      <h2>Forma de Envío</h2>
      <label class="radio-option">
        <input type="radio" v-model="formaDeEnvio" value="retiro_local"> Retiro en local
      </label>
      <label class="radio-option">
        <input type="radio" v-model="formaDeEnvio" value="envio_domicilio"> Envío a domicilio (Solo Florencio Varela)
      </label>

      <label class="radio-option">
        <input :disabled="isFrio" type="radio" v-model="formaDeEnvio" value="envio_correo">
        <span class="correo" :style="{textDecoration: isFrio ? 'line-through' : 'none'}">Envío por correo</span>
        <br>
        <small v-if="isFrio">Envio por correo no disponible para productos que precisan continuacion en su cadena de
          frio</small>
        <a @click="() => showModalProductos = true"><small v-if="isFrio" class="prod">Ver productos</small></a>
      </label>

      <h2>Método de Pago</h2>
      <label class="radio-option" v-if="formaDeEnvio === 'retiro_local' || formaDeEnvio === 'envio_domicilio'">
        <input type="radio" v-model="metodoDePago" value="efectivo"> Efectivo <br>
        <input type="radio" v-model="metodoDePago" value="transferencia"> Transferencia
      </label>
      <label class="radio-option" v-else>
        <input type="radio" v-model="metodoDePago" value="transferencia"> Transferencia
      </label>
      <button class="checkout__btn auth__btn w-100">
        <a href="/verificacion" class='payment__link action'>
          Volver
        </a>
      </button>
      <button class="checkout__btn auth__btn w-100">
        <a @click.prevent="Continue" class='payment__link action'>
          Confirmar pedido
        </a>
      </button>
    </div>
  </div>
  </body>
</template>


<style lang="scss" scoped>

.content {
  min-height: 400px;
  justify-content: unset;
}

span {
  margin-left: 4px;
}

.title {
  h2 {
    text-align: center;
  }
}

small {
  color: red;
  margin-left: 1em;
  display: block;
  pointer-events: none;
  user-select: none;
  margin-top: .5em;
}

.prod, a {
  color: dodgerblue;

  &:hover {
    text-decoration: underline;
  }
}

.action {
  color: black !important;
}


.productoFrio {
  margin-bottom: 1em;
}

.modalProductosFrios {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.42);
}

.cerrar {
  position: absolute !important;
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
  background: var(--primary-orange);
}

.shipping-container {
  margin-top: 120px;
  padding: 80px;
}

.shipping-form {
  background: #f1f1f1;
  font-family: Arial, sans-serif;
  max-width: 400px;
  margin: 0 auto;
  padding: 18px;
  border-radius: 20px;

  h2 {
    font-size: 1.5rem;
    margin-bottom: 0.6rem;
    padding: 0.4rem 0;
  }

  .radio-option {
    display: block;

    input[type="radio"] {
      margin-right: 0.5rem;
      cursor: pointer;
    }
  }
}

.checkout__btn {
  margin-top: 20px;
  padding: 8px;
  background-color: #fff;
  border: none;
  border-radius: 40px;
  font-weight: 600;
  margin-right: 10px;
}

.checkout__btn:hover {
  transform: scale(1.05);
  transition: .3s ease-out;
}

@media only screen and (max-width: 768px) {
  .shipping-container {
    margin-top: 160px;
    padding: 20px;
  }
}
</style>
  