import { createStore } from "vuex";
import table from "@/store/table";
import { post } from "../../services/apiReq";

export default createStore({
  state: {},
  getters: {
    getUser() {
      if (localStorage.getItem("dp_user"))
        return JSON.parse(localStorage.getItem("dp_user"));
    },
  },
  mutations: {},
  actions: {
    async getProducts({ dispatch }, value) {
      let res = await post("/fortaleza/productos", {page: value?.page, categoria: value?.categoria, nombre: value?.nombre, order: value?.order, direction: value?.direction});
      console.log(res)
      return res;

    },
    async sendContact({ dispatch }, value) {
      await post("/fortaleza/contacto", value);
    },
    async login({ dispatch }, value) {
      let res = await post("/user/login", value);

      if (res?.data?.user) {
        localStorage.setItem("dp_user", JSON.stringify(res?.data.user));
        location.href = "/";
      }
    },
    async register({ dispatch }, value) {
      let res = await post("/user/register", value);
      if (res?.data?.user) {
        localStorage.setItem("dp_user", JSON.stringify(res?.data?.user));
        location.href = "/";
      }
    },
    async logout() {
      localStorage.removeItem("dp_user");
      location.href = "/";
    },
  },
  modules: {
    table,
  },
});
