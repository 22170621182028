
<template>
  <Header/>
  <nav>
    
  </nav>
  <div id="notification-container"></div>
  <router-view/>
  <Footer/>
</template>

<style lang="scss">
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", "sans-serif" !important;
    font-style: normal;
}

:root {
  --primary-orange:#f45e02;
}

html {
    scroll-behavior: smooth;
    /*scroll-padding-top: 75px;*/
}

body {
    margin-top: 80px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  ::-webkit-scrollbar {
    display: none;
  }

  // || Con min-height es importante no tener overflows definidos
  // || para el uso de position sticky.
  overflow-x: unset !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
}

ul {
    list-style: none;
}
  
a {
    text-decoration: none;
    color: unset;
}

#notification-container {
  position: fixed;
  top: 0;
  right: 0;
  margin-right: 1em;
  z-index: 999999;

  pointer-events: none;
}

.notification {
  padding: 1em;
  background: #f45e02;
  color: white;
  margin: 1em 0;
  min-width: 200px;

  pointer-events: none;
  user-select: none;
  z-index: 1000;
}

.notification-enter {
  animation-name: notificationAnimationEnter;
  animation-duration: 0.25s;
}

.notification-exit {
  animation-name: notificationAnimationExit;
  animation-duration: 0.25s;
}

@keyframes notificationAnimationEnter {
  0% {
    transform: translateX(150%);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes notificationAnimationExit {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(150%);
  }
}
</style>

<script setup>
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';
import {onMounted} from "vue";
import {post} from "../services/apiReq";

onMounted(async () => {
  // || Testing
  /*
  let response = await post("/fortaleza/zippin/cotizar", {
    declared_value: 9000,
    destination: {
      city: "Burzaco",
      state: "Buenos aires", // Posible error
      zipcode: 1852,
      country: "Argentina"
    },
    items: [
      {
        sku: null,
        description: "Objeto de testing",
        weight: 100,
        length: 10,
        height: 5,
        width: 5,
        classification_id: 1,
      }
    ],
  });
  console.log("TESTING: ", response);
  */
})
</script>
